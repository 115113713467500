<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="this.loading" :backdrop="true" />
    <div class="form-row">
      <div class="form-col">
        <label for="categories" :class="{ isDisabled: this.allResponses }">main topic</label>
        <Dropdown
          id="categories"
          trackBy="intent_category_name"
          label="intent_category_name"
          :options="categories"
          :value="category"
          @input="setCategoryValue"
          :disabled="responseId !== '' || this.allResponses"
          objectMode
        />
      </div>
      <div class="form-col">
        <label for="intents" :class="{ isDisabled: !this.category && responseId !== '' || this.allResponses }">
          conversation detail
        </label>
        <Dropdown
          id="intents"
          trackBy="intent_name"
          :searchable="false"
          label="suggestion_text"
          :options="intents"
          :value="intent"
          @input="setIntentValue"
          :disabled="responseId !== '' || !this.category || this.allResponses"
          objectMode
        />
      </div>
      <div class="form-col">
        <label for="sub_intent" :class="{ isDisabled: !this.intent || responseId !== '' || this.allResponses }">
          next level detail
        </label>
        <Dropdown
          id="sub_intent"
          trackBy="intent_name"
          label="suggestion_text"
          :searchable="false"
          :options="subIntents"
          :value="subIntent"
          @input="setSubIntentValue"
          :disabled="responseId !== '' || !this.intent || this.allResponses"
          objectMode
        />
      </div>
    </div>
    <div class="my-1 w-1/6">
      <label class="flex items-center pl-1 text-sm mb-1">
        <input class="mr-2" name="all_custom_responses" type="checkbox" v-model="allResponses" @change="fetchAllResponses($event)" />
        <span class="text-blue-500 underline">show all custom responses</span>
      </label>
    </div>
    <div
      class="flex-1 flex-col overflow-auto p-2 focus:border-gray-700"
      v-if="category && intent && subIntent || this.allResponses"
    >
      <detail-custom-response
        v-for="response in responses.CommunityIntentUsesCommunityIntentResponse"
        :key="response.intent_response_id"
        :response="response"
        :selectedResponseId="selectedResponseId"
        :onSelectToPromote="
          () => (
            (responseId = response.intent_response_id),
            (selectedResponseId = response.intent_response_id),
            (communityIntentId = response.community_intent_id),
            (isFlagged = false),
            (isApproved = false),
            (isBlocked = false),
            (isDelete = false),
            (isPromote = true)
          )
        "
        :onSelectToFlagged="
          () => (
            (responseId = response.intent_response_id),
            (selectedResponseId = response.intent_response_id),
            (isPromote = false),
            (isApproved = false),
            (isBlocked = false),
            (isDelete = false),
            (isFlagged = true),
            (isFlaggedState = response.flagged)
          )
        "
        :onSelectToApproved="
          () => (
            (responseId = response.intent_response_id),
            (selectedResponseId = response.intent_response_id),
            (isPromote = false),
            (isFlagged = false),
            (isBlocked = false),
            (isDelete = false),
            (isApproved = true)
          )
        "
        :onSelectToBlocked="
          () =>
            handleBlockedResponse(
              response.intent_response_id,
              response.blocked,
              response.disabled,
              response.community_id
            )
        "
      />
    </div>

    <modal-footer
      :footer="footerPromote"
      :primary="() => onPromote(true)"
      :secondary="() => onPromote(false)"
      :tertiary="() => (
        (isPromote = false),
        (selectedResponseId = ''),
        (responseId = '')
      )"
      v-if="isPromote"
    />

    <modal-footer
      :footer="{
        primaryButton: 'cancel',
        secondaryButton: this.actionName,
      }"
      :primary="
        () => (
          (isFlagged = false),
          (isApproved = false),
          (isBlocked = false),
          (selectedResponseId = ''),
          (responseId = '')
        )
      "
      :secondary="onActions"
      v-if="isFlagged || isApproved || isBlocked"
    />
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import GlobalResponsesMixin from '@/components/digital_human/GlobalResponsesMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import Dropdown from '@/components/ui/Dropdown'
import DetailCustomResponse from '@/components/digital_human/DetailCustomResponse'

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, GlobalResponsesMixin],
  components: {
    DetailCustomResponse,
    Loader,
    ModalFooter,
    Dropdown,
  },
  computed: {
    intentName: function() {
      return this.subIntent && this.subIntent?.intent_name !== 'general'
        ? this.subIntent?.intent_name
        : this.intent?.intent_name
    },
    actionName: function() {
      return !this.isFlaggedState && this.isFlagged
        ? 'flag'
        : this.isFlaggedState && this.isFlagged
        ? 'unflag'
        : this.isApproved
        ? 'approve'
        : !this.isBlockedState && this.isBlocked
        ? 'block'
        : this.isBlockedState && this.isBlocked
        ? 'unblock'
        : 'test'
    },
  },
  data() {
    return {
      allResponses: false,
      responseId: '',
      communityResponseId: '',
      communityIntentId: '',
      responseText: '',
      isPromote: false,
      isFlagged: false,
      isFlaggedState: false,
      isApproved: false,
      isBlocked: false,
      isBlockedState: 0,
      isDisabled: 0,
      isDelete: false,
      defaultResponseId: '',
      selectedResponseId: '',
      defaultResponse: false,
      indicates_feature_not_present: 0,
      footerPromote: {
        primaryButton: 'yes, promote as positive',
        secondaryButton: 'yes, promote as negative',
        tertiaryButton: 'cancel'
      },
      footerDelete: {
        primaryButton: 'cancel',
        secondaryButton: 'yes, delete',
      },
    }
  },
  methods: {
    handleBlockedResponse(responseId, isBlocked, isDisabled, communityResponseId) {
      this.selectedResponseId = responseId
      this.responseId = responseId
      this.communityResponseId = communityResponseId
      this.isPromote = false
      this.isFlagged = false
      this.isApproved = false
      this.isDelete = false
      this.isBlocked = true
      this.isBlockedState = isBlocked
      this.isDisabled = isDisabled
    },
    handleUnCheckResponse() {
      this.setLoading(true)
      const intentValue =
        this.subIntent?.intent_name === 'general' ? this.intent : this.subIntent
      this.$dhDataProvider
        .create('toggleCustomResponse', {
          data: {
            intent_response_id: this.responseId,
            intent_name: intentValue.intent_name,
            community_id: this.communityResponseId,
          },
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchCategories() {
      this.setLoading(true)

      this.$dhDataProvider
        .getList('categories')
        .then((res) => {
          this.loadCategories(res)
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchIntents(categoryName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('intents', { category: categoryName })
        .then((res) => this.loadIntents(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchSubIntents(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('subIntentsDetail', { intent: intentName })
        .then((res) => this.loadSubIntents(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchResponses(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('allCustomIntentResponses', { intent: encodeURIComponent(intentName) })
        .then((res) => this.loadResponses(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => {
          this.setLoading(false)
          this.isDisplay = false
        })
    },
    fetchAllResponses() {
      this.cleanState('category');
      this.cleanState('intent');
      this.cleanState('subIntent');
      this.isFlagged = false;
      this.isApproved = false;
      this.isBlocked = false;
      this.isPromote = false;

      if (this.allResponses) {
        this.setLoading(true)
        this.$dhDataProvider
          .getList('allCommunityCustomIntentResponses')
          .then((res) => this.loadResponses(res))
          .catch((error) => this.notifyError(error.message))
          .finally(() => {
            this.setLoading(false)
            this.isDisplay = false
          })
      }
    },
    setCategoryValue(value) {
      this.cleanState('category')
      this.isFlagged = false
      this.isApproved = false
      this.isBlocked = false
      this.isPromote = false
      if (!value) return
      this.setCategory(value)
      this.fetchIntents(value?.description)
      this.isDisplay = false
    },
    setIntentValue(value) {
      this.cleanState('intent')
      this.isFlagged = false
      this.isApproved = false
      this.isBlocked = false
      this.isPromote = false
      if (!value) return
      this.setIntent(value)
      this.fetchResponses(value?.intent_name)
      this.fetchSubIntents(value?.intent_name)
    },
    setSubIntentValue(value) {
      this.cleanState('subIntent')
      this.isFlagged = false
      this.isApproved = false
      this.isBlocked = false
      this.isPromote = false
      if (!value) return
      const subIntentValue =
        value?.intent_name === 'general' ? this.intent : value
      this.setSubIntent(value)
      this.fetchResponses(subIntentValue.intent_name)
    },
    onChangeCustomResponse() {
      this.setLoading(true)
      this.$dhDataProvider
        .update('customResponse', {
          id: this.responseId,
          data: {
            approved: this.isApproved,
            flagged: this.isFlagged,
          },
        })
        .then(() => {
          this.notifySuccess('The custom response was successfully updated')
          this.isApproved = false
          this.isFlagged = false
          this.isBlocked = false
          this.responseId = ''
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => {
          if (this.allResponses) {
            this.fetchAllResponses();
          } else {
            this.fetchResponses(this.intentName)
          }
          this.setLoading(false)
        })
    },
    onPromote(isPositive) {
      this.setLoading(true)

      this.$dhDataProvider
        .create('promoteResponse', {
          communityId: this.communityIntentId,
          intentId: this.responseId,
          isPositive: isPositive,
          data: '',
        })
        .then(() => {
          this.responseId = ''
          this.communityIntentId = ''
          if (this.allResponses) {
            this.fetchAllResponses();
          } else {
            this.fetchResponses(this.intentName)
          }
          this.notifySuccess('The custom response was successfully promoted')
          this.isPromote = false
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    onBlock() {
      this.setLoading(true)
      this.$dhDataProvider
        .update('blockCustomResponse', { id: this.responseId, data: '' })
        .then(() => {
          if (!this.isBlockedState) {
            this.notifySuccess('The custom response was successfully freezed')
          } else {
            this.notifySuccess('The custom response was successfully unfreezed')
          }
          this.isBlocked = false
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => {
          if (this.allResponses) {
            this.fetchAllResponses();
          } else {
            this.fetchResponses(this.intentName)
          }
          if (this.isDisabled === 0) this.handleUnCheckResponse()
          else this.responseId = ''
          this.setLoading(false)
        })
    },
    onActions() {
      this.setLoading(true)
      if (this.isBlocked) {
        this.selectedResponseId = ''
        this.onBlock()
      } else {
        this.selectedResponseId = ''
        this.onChangeCustomResponse()
      }
    },
  },
  created() {
    this.fetchCategories()
    if (this.category && this.intent && this.subIntent) {
      const value =
        this.subIntent?.intent_name === 'general' ? this.intent : this.subIntent
      this.fetchResponses(value.intent_name)
    }
  },
  unmounted() {
    if (!this.backModal) {
      this.cleanState('category')
    }
  },
}
</script>

<style scoped>
.active {
  @apply text-blue-500;
}
.isDisabled {
  @apply text-gray-400;
}
</style>

<template>
  <div
    class="pb-2 border border-gray-100 border-1 mb-3 container-response p-4"
    :class="{ selectedResponse: isActive(response.intent_response_id) }">
    <div class="flex">
      <div class="flex justify-between w-full mb-1">
        <span 
        class="w-2 h-2 rounded-full m-2" 
        style="background-color: var(--highlightColor300);">
        </span>
        <p class="flex-1 mx-2 m-px font-bold">
          {{ response.intentResponse_response_text }}
        </p>
      </div>

       <div class="flex">

        <a  v-on="response.blocked ? {} : {click: this.onSelectToFlagged}" class="cursor-pointer" v-bind:class="{'isDisabled': response.blocked}" >
          <icon v-if="response.flagged" name="flag" class="h-5 w-5 mr-1 text-red-500"></icon>
          <icon v-else  name="flag" class="h-5 w-5 mr-1 text-color"></icon>
        </a>
         <a v-on="response.blocked ? {} : {click: this.onSelectToApproved}" class="cursor-pointer" v-bind:class="{'isDisabled': response.blocked}">
          <icon name="thumbUp" class="h-5 w-5 mr-1 text-color"></icon>
        </a>
        <a v-on="response.blocked ? {} : {click: this.onSelectToPromote}" class="cursor-pointer" v-bind:class="{'isDisabled': response.blocked}">
           <icon name="glove" class="h-5 w-5 mr-1 text-color"></icon>
        </a>
        <a @click="this.onSelectToBlocked" class="cursor-pointer">
           <icon name="ban" class="h-5 w-5 mr-1 text-color" :class="{'disabledBan' : response.blocked}"></icon>
        </a>        
      </div>
    </div>
   
    <div class="flex items-center justify-between">
      <div class="mx-8">
        <span 
          class="flex-1 m-px text-gray-600"
          v-if="response.spanish_response_text"
        >
          {{ response.spanish_response_text }}
          <p v-if="response.status=='outDatedTranslated'" class="mt-1 text-yellow-300">
            outdated translation
          </p>
        </span>
        <span 
          v-else
          strong class="m-px text-red-600"
        >
           no spanish translation
        </span>
        <p class="font-bold text-sm break-normal text-color" v-if="response.intent_category_name">
          Intent: {{ response.intent_category_name }} > {{ response.intent_name_parent }} > {{ response.intent_name_child }}
        </p> 
        <p class="font-bold text-sm break-normal text-color ">
          {{ response.community_name }} : {{ response.customer_name }}
        </p> 
      </div>
      <div class="font-bold text-sm text-color">
        Created: {{response.creation_date}}
      </div>
    </div>

  </div>
</template>

<script>
import Icon from '@/components/ui/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    response: {
      type: Object,
      default() {
        return {}
      },
    },
    selectedResponseId: {
      type: String,
      default: '',
      required: true,
    },
    onSelectToPromote: {
      type: Function,
      required: true,
    },
    onSelectToFlagged: {
      type: Function,
      required: true,
    },
    onSelectToApproved: {
      type: Function,
      required: true,
    },
    onSelectToBlocked: {
      type: Function,
      required: true,
    },
  },
  methods: {
    isActive (id) {
      if (this.$props.selectedResponseId === id) return true;
      return false; 
    }
  },
}
</script>

<style scoped>
  .isDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
  .isDisabled:hover {
    --text-opacity: 1;
    color: #3498DB;
    color: rgba(52, 152, 219, var(--text-opacity));
  }
  .disabledBan {
    @apply text-red-500 !important;
  }
  .container-response:hover{
    background-color: #ebf8ff;
  }
  .selectedResponse {
    background-color: #ebf8ff;
  }

  .text-color{
     color: var(--highlightColor500);
  }
  .text-color:hover{
    color: var(--highlightColor600);
  }
</style>